<template>
  <v-card class="pa-0">
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>Exam Board </v-card-title>
      <v-spacer> </v-spacer>
      <!-- <v-dialog max-width="600px" v-model="dialogNewFee"> -->
      <!-- <template v-slot:activator="{ attrs, on }"> -->
      <!-- <v-btn v-bind="attrs" class="ma-4" dark v-on="on" to="/organization/notice/new/" outlined>Add New </v-btn>
           -->
      <v-dialog v-model="mainDialog" persistent max-width="50%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"> Add New </v-btn>
        </template>
        <v-card>
          <v-toolbar width="100%" class="mx-0" color="primary " dark>
            <v-card-text class="text-h5">Add New Examboard</v-card-text>
            <v-spacer> </v-spacer>
            <v-btn icon @click="closeExam">
              <v-icon large color="red lighten-2"
                >mdi-close-circle-outline</v-icon
              >
            </v-btn>
          </v-toolbar>

          <v-form
            ref="form"
            v-on:submit.prevent="save"
            class="pa-6"
            v-model="exitexam"
          >
            <v-text-field
              v-model="title"
              label="Title*"
              hide-details="auto"
              :rules="$requiredRules"
            ></v-text-field>

            <v-text-field
              v-model="detail"
              label="Short Description"
            ></v-text-field>
            <!-- <input type="file" @change="handleFileUpload( $event )"/> -->

            <v-file-input
              v-model="file"
              type="file"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an Image"
              prepend-icon="mdi-camera"
              label="Upload Image"
            ></v-file-input>

            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn color="blue darken-1" text @click="$emit('closeDialog')">
        Close
      </v-btn> -->
              <v-btn type="submit" color="secondary"> Save </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- </template> -->
      <!-- <new-fee :room_id="room_id" @closeDialog="closeDialogNewFee"></new-fee> -->
      <!-- </v-dialog> -->
    </v-toolbar>
    <v-row
      style="
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border: 1px solid;
        margin-bottom: 10px;
      "
    >
      <!-- <v-col style="font-weight: bold;">

  

  </v-col> -->

      <v-col>
        <div>
          <v-row style="margin-top: 10px; margin-left: 2px; font-weight: bold">
            Filter by Date</v-row
          >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="start_date"
                label="Start Date"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="end_date"
                label="End Date"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <DataTableSSR
      apiEndPoint="examboard/"
      :headers="headers"
      :instantLoad="true"
      :key="refreshKey"

      :queryParams="{
        start_date: start_date,
        end_date: end_date,
      }"
    >
      <template #[`item.show`]="{ item }">
        <v-dialog v-model="item.examdialog" width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-input v-bind="attrs" v-on="on" id="title" color="black">
              {{ item.title.substring(0, 20) + ".." }}
            </v-input>
          </template>
          <v-card v-model="examdetail">
            <v-card-title>
              <v-row>
                <v-col cols="12" md="8" lg="11">
                  <span class="text-h5">Exam Details</span>
                </v-col>
                <v-col cols="12" md="4" lg="1" ml="14" pl="2">
                  <v-btn icon @click="item.examdialog = false">
                    <v-icon large color="red lighten-2"
                      >mdi-close-circle-outline</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-toolbar color="primary" dark dense flat>
                  <v-toolbar-title class="text-body-2">
                    {{ item.title }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  {{ item.detail }}
                </v-card-text>
                <v-card-text>
                  <v-img :src="item.file" height="205px">
                    <v-card-title class="text-h6 white--text">
                      <v-row
                        class="fill-height flex-column"
                        justify="space-between"
                      >
                      </v-row>
                    </v-card-title>
                  </v-img>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-dialog v-model="item.dialog" max-width="700px">
          <template v-slot:activator="{ on, attrs }">
            <v-row>
              <v-btn
                v-bind="attrs"
                :color="item.is_active ? 'primary' : 'warning'"
                :disabled="!item.is_active"
                class="ma-4"
                v-on="on"
                outlined
              >
                Edit
              </v-btn>
              <v-switch
                depressed
                :input-value="item.is_active"
                @change="toggleActive(item)"
              ></v-switch>
            </v-row>
          </template>
          <exam-update
            :item="item"
            :item_id="item.id"
            @closeDialog="item.dialog = false"
            
          ></exam-update>
          
        </v-dialog>
      </template>
    </DataTableSSR>
  </v-card>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";

import ExamUpdate from "./ExamUpdate.vue";
export default {
  components: { DataTableSSR, ExamUpdate },
  props: ["item", "item_id"],
  data: () => ({
    examdialog: false,
    examboard: {
      title: "",
      detail: "",
      file: "",
    },
    refreshKey: 0,

    start_date: null,
    end_date: null,
    mainDialog: false,
    headers: [
      {
        text: "Title",
        value: "show",
      },

      {
        text: "Created",
        value: "created_at",
      },

      {
        text: "Action",
        value: "action",
      },
    ],
    dialog: false,
    examdetail: false,
  }),
  emits: ["closeDialog"],

  async created() {
    await this.loadExam();
  },
  computed: {
    rowClasses() {
      return (item) => ({
        "active-row": item.is_active,
        "inactive-row": !item.is_active,
      });
    },
  },

  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    loadExam() {
      return this.$api
        .get(`/examboard/${this.item.id}`)
        .then((response) => {
          this.examboard = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    save() {
      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("detail", this.detail);

      formData.append("file", this.file);

      this.$api
        .post(`/examboard/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
       
        .then(() => {
          this.refreshKey += 1;

          this.mainDialog = false;
        
          this.resetValues();
        });
    },
    resetValues() {
      this.title = null;
      this.detail = null;
      this.file = null;
    },

    closeDialogNewFee() {
      this.dialogNewFee = false;
    },
    closeExam() {
      this.mainDialog = false;
    },
    toggleActive(item) {
      const newActiveState = !item.is_active;

      const formData = new FormData();
      formData.set("is_active", newActiveState);
      formData.set("title", item.title);
      formData.set("detail", item.detail);

      if (
        this.file &&
        this.file instanceof Blob &&
        this.file.type &&
        this.file.size
      ) {
        formData.set("file", this.file, this.file.name);
      }

      this.$api
        .put(`/examboard/${item.id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          item.is_active = res.data.is_active;
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
};
</script>

<style>
.btn {
  text-transform: unset !important;
}
</style>
