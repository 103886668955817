<template>
  <v-card>
    <v-toolbar width="100%" class="mx-0" color="primary " dark>
      <v-card-text class="text-h5">Exam Board </v-card-text>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('closeDialog')">
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-form
      ref="form"
      v-on:submit.prevent="save"
      class="pa-6"
      v-model="exitexam"
    >
      <v-text-field
        v-model="examboard.title"
        label="Title*"
        hide-details="auto"
        :rules="$requiredRules"
      ></v-text-field>

      <v-text-field
        v-model="examboard.detail"
        label="Short Description*"
      ></v-text-field>

      <v-file-input
        v-model="file"
        type="file"
        accept="image/png, image/jpeg, image/bmp"
        placeholder="Pick an Image"
        prepend-icon="mdi-camera"
        label="Upload Image"
      ></v-file-input>

      <v-row class="fill-height" align="center" justify="center">
        <v-col>
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-img :src="item.file" height="300px" min-width="100%">
                <v-card-title class="text-h6 white--text">
                  <v-row
                    class="fill-height flex-column"
                    justify="space-between"
                  ></v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" color="secondary" @click="update">Update</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: ["item"],
  emits: ["closeDialog"],
  data() {
    return {
      dialogNewFee: false,
      examboard: {
        title: "",
        detail: "",
        assignments: "",
      },
      file: null,
    };
  },
  async created() {
    await this.loadExam();
  },
  computed: {
    imageUrl() {
      return this.examboard.file
        ? URL.createObjectURL(this.examboard.file)
        : null;
    },
  },
  methods: {
    async loadExam() {
      try {
        const response = await this.$api.get(`examboard/${this.item.id}`);
        this.examboard = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    update() {
      const formData = new FormData();
      formData.set("title", this.examboard.title);
      formData.set("detail", this.examboard.detail);
      formData.set("is_active", this.examboard.is_active);

      if (this.file) {
        formData.set("file", this.file);
      }
      this.$api
        .put(`/examboard/${this.item.id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.examboard = res.data;
          this.$emit("closeDialog");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
